<template>
    <div>
        <v-container>
            <v-card style="background-color: #f5f5f5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    estado de solicitud
                </div>

                <v-form
                  ref="form"
                  v-model="isValid"
                  autocomplete="off">
                <!-- FORMULARIO ESTADO -->
                <v-row>
                    <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="solSta.SolStaCod"
                            label="Código">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="solSta.SolStaNom"
                            required
                            :rules="[v => !!v || 'El nombre es obligatorio']"
                            label="Nombre *">
                        </v-text-field>
                    </v-col>
                </v-row>
                  
                <!-- ACEPTAR / CANCELAR / BORRAR -->
                <v-row class="mt-4" justify="center">
                    <v-btn
                        :loading="loading"
                        :disabled="!isValid"
                        @click="onBtnSave"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Aceptar
                    </v-btn>
                    
                    <v-btn
                        to="/estados-solicitud"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Cancelar
                    </v-btn>

                    <v-btn v-if="!isNew"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal()">
                        Borrar
                    </v-btn>
                </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modal de borrado -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR ESTADO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el estado? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      :loading="loading"
                      color="primary"
                      @click="onBtnDelete">
                      Aceptar
                  </v-btn>
                  <v-btn @click="onBtnCancelDelete">
                      Cancelar
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    // components: {},
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            solSta: {
                SolStaId: 0,
                SolStaCod: '',
                SolStaNom: ''
            }
        }
    },
    // watch: {
    //   solSta: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.solSta.SolStaId = this.$route.params.id;
        this.loadData();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/estados-solicitud');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_SOL_STA',
            SolStaId: this.solSta.SolStaId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/solsta", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.SolSta;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el estado.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            SolSta: this.solSta
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/solsta", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el estado.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            SolStaId: this.solSta.SolStaId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/solsta", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar el estado.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Estado no encontrado.');
              this.goToList();
              return;  
            }

            this.solSta = data.item;
          });
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.SolStaId != null) {
                this.goToList();
            }
            else {
              alert('Error al guardar el estado.');
            }
          });
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      //#endregion

      //#region Validar formulario
      validate() {
          this.$refs.form.validate();
      }
      //#endregion
    } 
})
</script>
